import { buildEggplantTheme } from '@botpress/webchat-generator'
import { blue } from '@radix-ui/colors'
import { Flex, Text } from '@radix-ui/themes'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Card, Button, EmptyState, Icon } from '~/elementsv2'
import { useWebchatMessages } from '~/hooks'
import { ImageRenderer } from './ImageRenderer'
import { WebchatProvider } from '@botpress/webchat'
import { Message } from './Message'
import { match } from 'ts-pattern'
import { HiOutlineChatBubbleLeftRight } from 'react-icons/hi2'
import { cn } from '~/utils'

type MessageListProps = {
  botId: string
  conversationId: string
  workspaceId: string
  messageId?: string
  className?: string
}
const MessageList = ({ botId, conversationId, workspaceId, messageId, className }: MessageListProps) => {
  const { theme, style } = useMemo(() => buildEggplantTheme(blue.blue3, { twin: { preflight: false } }), [])

  const {
    data: fetchedMessages,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useWebchatMessages({
    botId,
    conversationId,
    workspaceId,
  })

  const [initialized, setInitialized] = useState(!messageId)

  const [scrollHeight, setScrollHeight] = useState(0)
  const messages = fetchedMessages?.pages?.flatMap((page) => page.messages).reverse() ?? []

  const scrollToMessageRef = useRef<HTMLDivElement>(null)
  const scrollableMessagesRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (messageId && messages.findIndex((msg) => msg.id === messageId) === -1) {
      void fetchNextPage()
    }
  }, [messageId, messages.length])

  useEffect(() => {
    const scrollableElement = scrollableMessagesRef.current

    if (scrollableElement && scrollHeight !== scrollableElement.scrollHeight) {
      const diff = scrollableElement.scrollHeight - scrollHeight

      if (diff) {
        scrollableElement.scrollTo({
          top: diff,
        })
      }
      setScrollHeight(scrollableElement.scrollHeight)
    }
  }, [scrollableMessagesRef, messages.length, scrollHeight])

  useEffect(() => {
    if ((messageId && messages.findIndex((msg) => msg.id === messageId) !== -1) || !hasNextPage) {
      setInitialized(true)
    }
  }, [isFetching, messages.length, messageId])

  if (!initialized) {
    return (
      <Card className={cn('flex items-center justify-center border-dashed', className)}>
        <EmptyState />
        <Flex align={'center'} gap={'2'} className="opacity-60">
          <Icon color="gray" size="4" icon={HiOutlineChatBubbleLeftRight} />
          <Text color="gray">Loading messages...</Text>
        </Flex>
      </Card>
    )
  }

  if (!isFetching && !messages.length) {
    return (
      <Card className={cn('flex items-center justify-center border-dashed', className)}>
        <EmptyState />
        <Flex align={'center'} gap={'2'} className="opacity-60">
          <Icon color="gray" size="4" icon={HiOutlineChatBubbleLeftRight} />
          <Text color="gray">No messages in conversation</Text>
        </Flex>
      </Card>
    )
  }

  return (
    <Card ref={scrollableMessagesRef} className={cn('overflow-auto', className)}>
      <Flex direction={'column'} gap={'2'}>
        <WebchatProvider isReadOnly renderers={{ image: ImageRenderer }} theme={theme}>
          <Button
            loading={isFetching}
            variant="ghost"
            className="mx-auto my-2 w-fit"
            onClick={() => void fetchNextPage()}
            disabled={!hasNextPage}
          >
            {hasNextPage ? 'Load more' : 'End of conversation'}
          </Button>
          {messages?.map((message, i) => (
            <Message
              ref={match({ messageId, i })
                .with({ messageId: message.id }, () => scrollToMessageRef)
                .with({ i: messages.length - 1, messageId: undefined }, () => scrollToMessageRef)
                .otherwise(() => undefined)}
              key={message.id}
              {...message}
              selected={messageId === message.id}
            />
          ))}
        </WebchatProvider>
      </Flex>
      <style>{style}</style>
    </Card>
  )
}

export { MessageList }
