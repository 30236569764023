// this file was automatically generated, do not edit
/* eslint-disable */

export interface CreateInterfaceRequestHeaders {}

export interface CreateInterfaceRequestQuery {}

export interface CreateInterfaceRequestParams {}

export interface CreateInterfaceRequestBody {
  /**
   * Name of the [Interface](#schema_interface)
   */
  name: string;
  /**
   * Version of the [Interface](#schema_interface)
   */
  version: string;
  events: {
    /**
     * Event Definition
     */
    [k: string]: {
      /**
       * Title of the event
       */
      title?: string;
      /**
       * Description of the event
       */
      description?: string;
      schema: {
        [k: string]: any;
      };
    };
  };
  actions: {
    /**
     * Action definition
     */
    [k: string]: {
      /**
       * Title of the action
       */
      title?: string;
      /**
       * Description of the action
       */
      description?: string;
      billable?: boolean;
      cacheable?: boolean;
      input: {
        schema: {
          [k: string]: any;
        };
      };
      output: {
        schema: {
          [k: string]: any;
        };
      };
    };
  };
  entities: {
    /**
     * Entity definition
     */
    [k: string]: {
      /**
       * Title of the entity
       */
      title?: string;
      /**
       * Description of the entity
       */
      description?: string;
      schema: {
        [k: string]: any;
      };
    };
  };
}

export type CreateInterfaceInput = CreateInterfaceRequestBody & CreateInterfaceRequestHeaders & CreateInterfaceRequestQuery & CreateInterfaceRequestParams

export type CreateInterfaceRequest = {
  headers: CreateInterfaceRequestHeaders;
  query: CreateInterfaceRequestQuery;
  params: CreateInterfaceRequestParams;
  body: CreateInterfaceRequestBody;
}

export const parseReq = (input: CreateInterfaceInput): CreateInterfaceRequest & { path: string } => {
  return {
    path: `/v1/admin/interfaces`,
    headers: {  },
    query: {  },
    params: {  },
    body: { 'name': input['name'], 'version': input['version'], 'events': input['events'], 'actions': input['actions'], 'entities': input['entities'] },
  }
}

export interface CreateInterfaceResponse {
  interface: {
    /**
     * ID of the [Interface](#schema_interface)
     */
    id: string;
    /**
     * Creation date of the [Interface](#schema_interface) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the [Interface](#schema_interface) in ISO 8601 format
     */
    updatedAt: string;
    /**
     * Name of the [Interface](#schema_interface)
     */
    name: string;
    /**
     * Version of the [Interface](#schema_interface)
     */
    version: string;
    events: {
      /**
       * Event Definition
       */
      [k: string]: {
        /**
         * Title of the event
         */
        title?: string;
        /**
         * Description of the event
         */
        description?: string;
        schema: {
          [k: string]: any;
        };
      };
    };
    actions: {
      /**
       * Action definition
       */
      [k: string]: {
        /**
         * Title of the action
         */
        title?: string;
        /**
         * Description of the action
         */
        description?: string;
        billable?: boolean;
        cacheable?: boolean;
        input: {
          schema: {
            [k: string]: any;
          };
        };
        output: {
          schema: {
            [k: string]: any;
          };
        };
      };
    };
    entities: {
      /**
       * Entity definition
       */
      [k: string]: {
        /**
         * Title of the entity
         */
        title?: string;
        /**
         * Description of the entity
         */
        description?: string;
        schema: {
          [k: string]: any;
        };
      };
    };
  };
}

