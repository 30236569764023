import { Text, Badge, Flex, ScrollArea } from '@radix-ui/themes'
import { useSuspenseInfiniteQuery, useSuspenseQuery as useTanstackSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, useNavigate, Link } from '@tanstack/react-router'
import { HiOutlineFunnel, HiOutlineTrash, HiOutlineXMark } from 'react-icons/hi2'
import { z } from 'zod'
import { Button, Page, type MenuItem, DropdownMenu, Avatar, IconButton, EmptyState } from '~/elementsv2'
import { ConversationDetails, ConversationList } from '~/features/hitl/components'
import { conversationStatusesMap } from '~/features/hitl/constants'
import { conversationStatuses, type ConversationStatus } from '~/features/hitl/types'
import { SidebarLayout } from '~/layoutsV2'
import { getIntegrationByNameQueryOptions, listHitlConversationsInfiniteQuery } from '~/queries'

import { getClient, HITLClient } from '~/features/hitl/client'
import { useEffect, useState } from 'react'
import { useAsync } from 'react-use'
import { HITLIntegrationNotEnabledError } from '~/features/hitl/client/errors'
import { useUpdateBot } from '~/hooks'
import { showSuccessToast, useSuspenseQuery } from '~/services'
import { HITLTabs } from '~/features/hitl/components/HITLTabs'

const paramsSchema = z.object({
  conversationId: z
    .string()
    .optional()
    .catch(() => undefined),
  assignee: z.string().optional(),
  status: z
    .enum(conversationStatuses)
    .optional()
    .catch(() => undefined),
})

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/hitl')({
  component: Component,
  validateSearch: paramsSchema,
})

function Component() {
  const { botId, workspaceId } = Route.useParams()
  const { conversationId, status, assignee: assigneeId } = Route.useSearch()
  const { mutate: updateBot } = useUpdateBot()
  const navigate = useNavigate({ from: Route.fullPath })

  const { plan } = useSuspenseQuery('workspaces_/$workspaceId_', { workspaceId }).data
  const workspaceMembers = useSuspenseQuery('workspaces_/$workspaceId_/members', { workspaceId }).data
  const assignee = workspaceMembers.find((m) => m.id === assigneeId)
  const hitlIntegration = useTanstackSuspenseQuery(getIntegrationByNameQueryOptions({ name: 'hitl', workspaceId })).data

  const [integrationNotInstalled, setIntegrationNotInstalled] = useState(false)
  const [integrationInstalling, setIntegrationInstalling] = useState(false)
  const [hitlClient, setHitlClient] = useState<HITLClient>()

  const initHitlClient = async () => {
    if (hitlClient?.workspaceId === workspaceId && hitlClient?.botId === botId) {
      return
    }
    try {
      const client = await getClient({ workspaceId, botId })
      setHitlClient(client)
    } catch (error) {
      if (error instanceof HITLIntegrationNotEnabledError) {
        setIntegrationNotInstalled(true)
      }
    }
  }

  useAsync(initHitlClient, [workspaceId, botId])

  const { data: fetchedConversations, refetch } = useSuspenseInfiniteQuery(
    listHitlConversationsInfiniteQuery({ botId, workspaceId })
  )

  useEffect(() => {
    const i = setInterval(refetch, 5000) // yes, long polling..
    return () => clearInterval(i)
  }, [])

  const allConversations = fetchedConversations.pages?.flatMap((page) => page.conversations) ?? []
  const conversations = allConversations.filter((c) => {
    return (status ? c.status === status : true) && (assigneeId ? c.assignee?.workspaceMemberId === assigneeId : true)
  })
  const currentConversation = allConversations.find((c) => c.id === conversationId)

  const conversationsFilterMenu: MenuItem[] = [
    {
      type: 'label',
      label: 'Filter by',
    },
    {
      type: 'submenu',
      content: 'Status',
      items: [
        {
          type: 'radioGroup',
          value: status,
          onValueChange: (value) =>
            navigate({
              params: { botId, workspaceId },
              search: { conversationId, assignee: assigneeId, status: value as ConversationStatus },
            }),

          items: Object.entries(conversationStatusesMap).map(([status, { title }]) => ({
            type: 'radioItem',
            value: status,
            content: title,
          })),
        },
      ],
    },
    {
      type: 'submenu',
      content: 'Assignee',
      items: [
        {
          type: 'radioGroup',
          onValueChange: (value) =>
            navigate({
              params: { botId, workspaceId },
              search: { conversationId, assignee: value, status },
            }),
          value: assigneeId,
          items: workspaceMembers.map(({ email, id, displayName, profilePicture }) => ({
            type: 'radioItem',
            value: id,
            content: displayName ?? email,
            leadingIcon: <Avatar size={'1'} name={email} pictureUrl={profilePicture} />,
          })),
        },
      ],
    },
    { type: 'separator' },
    {
      type: 'item',
      color: 'red',
      content: 'Clear filters',
      trailingIcon: <HiOutlineTrash />,
      onSelect: () =>
        navigate({
          params: { botId, workspaceId },
          search: { conversationId },
        }),
    },
  ]

  const installHITLIntegration = async () => {
    setIntegrationInstalling(true)
    updateBot({
      id: botId,
      workspaceId,
      integrations: {
        [hitlIntegration.id]: { enabled: true, configuration: {} },
      },
      onSuccess: async () => {
        await initHitlClient()
        setIntegrationNotInstalled(false)
        showSuccessToast(`HITL integration installed.`)
        setIntegrationInstalling(false)
      },
    })
  }

  return (
    <Page title="Human in the loop">
      {plan === 'community' ? (
        <Flex className="h-[55vh]">
          <EmptyState
            className="m-auto"
            title="Human in the Loop is restricted to Team plan"
            description="Upgrade your workspace to team plan to allow your collaborator to takover live user conversations."
            primaryAction={
              <Link to="/workspaces/$workspaceId/settings/billing/plans" params={{ workspaceId: workspaceId }}>
                <Button>Upgrade to Team Plan</Button>
              </Link>
            }
          />
        </Flex>
      ) : (
        <SidebarLayout
          main={
            hitlClient && currentConversation ? (
              <HITLTabs botId={botId} workspaceId={workspaceId} downstreamConversation={currentConversation} />
            ) : integrationNotInstalled ? (
              <EmptyState
                title="Missing HITL Integration"
                description={
                  <span>
                    <strong>Human in the Loop</strong> requires an integration to be installed and enabled.
                  </span>
                }
                primaryAction={
                  <Button loading={integrationInstalling} onClick={installHITLIntegration}>
                    <span>Install & enable HITL</span>
                  </Button>
                }
              />
            ) : allConversations.length === 0 ? (
              <EmptyState
                title="No Conversations"
                description="No conversations have been escalated yet, your bot is doing a great job!"
              />
            ) : !conversationId ? (
              <EmptyState
                title="No conversation selected"
                description="Select a conversation from the list to start chatting with the user."
              />
            ) : null
          }
          leftSidebarSize="4"
          leftSidebar={
            integrationNotInstalled || allConversations.length === 0 ? (
              <div />
            ) : (
              <Flex gap={'2'} direction={'column'} className="min-w-0">
                <Flex gap={'2'} wrap={'wrap'}>
                  <DropdownMenu color="gray" content={conversationsFilterMenu}>
                    <Button trailing={<HiOutlineFunnel />} variant={'outline'} color="gray" size={'2'}>
                      Filters
                    </Button>
                  </DropdownMenu>
                  {status && (
                    <Badge variant="surface" size={'2'} color={conversationStatusesMap[status].color}>
                      {conversationStatusesMap[status].title}
                      <IconButton
                        size={'1'}
                        className="my-0"
                        variant="ghost"
                        icon={HiOutlineXMark}
                        onClick={() =>
                          navigate({
                            params: { botId, workspaceId },
                            search: { conversationId, assignee: assigneeId, status: undefined },
                          })
                        }
                      />
                    </Badge>
                  )}
                  {assignee && (
                    <Badge className="min-w-0" variant="outline" size={'2'} color="gray">
                      <Flex gap={'2'} align={'center'}>
                        <Avatar
                          size={'1'}
                          className="size-4"
                          name={assignee.email}
                          pictureUrl={assignee.profilePicture}
                        />
                        <Text truncate>{assignee.displayName ?? assignee.email}</Text>
                        <IconButton
                          size={'1'}
                          className="my-0"
                          variant="ghost"
                          icon={HiOutlineXMark}
                          onClick={() =>
                            navigate({
                              params: { botId, workspaceId },
                              search: { conversationId, assignee: undefined, status },
                            })
                          }
                        />
                      </Flex>
                    </Badge>
                  )}
                </Flex>
                <ScrollArea scrollbars="vertical" className="h-[calc(100vh-24rem)] [&>div>div]:!w-auto">
                  <ConversationList
                    downstreamConversations={conversations}
                    botId={botId}
                    workspaceId={workspaceId}
                    currentConversationId={conversationId}
                  />
                </ScrollArea>
              </Flex>
            )
          }
          rightSidebar={
            currentConversation ? (
              <ConversationDetails botId={botId} workspaceId={workspaceId} conversation={currentConversation} />
            ) : (
              <div />
            )
          }
        />
      )}
    </Page>
  )
}
