import { Flex, Text, Separator, Switch } from '@radix-ui/themes'
import { createFileRoute } from '@tanstack/react-router'
import { useState } from 'react'
import { TwitterPicker } from 'react-color'
import { HiOutlineMail } from 'react-icons/hi'
import { HiOutlineGlobeAlt, HiOutlinePhone, HiOutlinePhoto, HiPhoto } from 'react-icons/hi2'
import { DialogFooter } from '~/componentsV2'
import { Button, Icon, Popover, Select, TextArea, TextInput, TextInputSlot, Spinner } from '~/elementsv2'
import { ConfigSection } from '~/features/webchat/components'
import { type ThemeName, type WebchatIntegrationConfig } from '~/features/webchat/schemas'
import { useUploadFile, useWebchatConfig, useWebchatV2 } from '~/hooks'
import { queryClient } from '~/providers/ReactQuery'
import { getQueryKey, showCustomDialog } from '~/services'
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'
import { useDropzone } from 'react-dropzone'
import { Callout } from '~/elementsv2'
import { contentType } from 'mime-types'
import {
  tomato,
  red,
  ruby,
  crimson,
  pink,
  plum,
  purple,
  violet,
  iris,
  indigo,
  blue,
  cyan,
  teal,
  jade,
  green,
  grass,
  orange,
  amber,
  yellow,
  lime,
} from '@radix-ui/colors'
import { nanoid } from 'nanoid'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/general')({
  component: Component,
})

export const baseColors = {
  tomato,
  red,
  ruby,
  crimson,
  pink,
  plum,
  purple,
  violet,
  iris,
  indigo,
  blue,
  cyan,
  teal,
  jade,
  green,
  grass,
  orange,
  amber,
  yellow,
  lime,
} as const

const themes = ['prism', 'galaxy', 'dusk', 'eggplant', 'dawn', 'midnight']

const pickerColors = Object.values(baseColors).map((palette) => Object.values(palette)[8] ?? '')

function Component() {
  const { workspaceId, botId } = Route.useParams()

  const { config, isPending, setConfig, updateWebchatIntegration } = useWebchatConfig({ botId, workspaceId })
  const { setIsWebchatV2Enabled, isWebchatV2Enabled } = useWebchatV2()

  return (
    <>
      <ConfigSection title="Settings" description="Configurations for the webchat.">
        <Flex gap={'4'} direction={'column'}>
          <Flex gap={'2'}>
            <Switch checked={isWebchatV2Enabled} onCheckedChange={(checked) => setIsWebchatV2Enabled(checked)} />
            <Text size={'2'} color="gray">
              Enable webchat version 2
            </Text>
          </Flex>
          <Flex gap={'4'} align={'center'}>
            <div
              className="group relative cursor-pointer"
              onMouseDown={() =>
                showCustomDialog({
                  content: (
                    <AvatarFormDialog
                      workspaceId={workspaceId}
                      botId={botId}
                      avatarUrl={config.avatarUrl ?? ''}
                      updateFormData={setConfig}
                    />
                  ),
                })
              }
            >
              <div className="absolute inset-0 z-10 flex items-center justify-center rounded-full bg-gray-11 opacity-0 transition-opacity group-hover:opacity-70">
                <HiOutlinePhoto className="aspect-square h-5 text-white" />
              </div>
              {config.avatarUrl ? (
                <img
                  className="aspect-square h-11 max-w-none rounded-full object-cover"
                  src={config.avatarUrl}
                  alt="Bot Avatar"
                />
              ) : (
                <div className="flex h-11 w-11 items-center justify-center rounded-full bg-gray-2">
                  <HiPhoto className="text-gray-9" />
                </div>
              )}
            </div>
            <TextInput
              label={'Bot Name'}
              placeholder="Your chatbot"
              value={config.botName}
              onChange={(e) => setConfig((prev) => ({ ...prev, botName: e.target.value }))}
              className="w-full"
            />
          </Flex>
          <TextArea
            label={'Description'}
            value={config.botConversationDescription}
            onChange={(e) => setConfig((prev) => ({ ...prev, botConversationDescription: e.target.value }))}
            placeholder="A brief description of your chatbot"
            className="w-full"
          />
          <TextInput
            label={'Composer placeholder'}
            value={config.composerPlaceholder}
            placeholder="Chat with bot"
            onChange={(e) => setConfig((prev) => ({ ...prev, composerPlaceholder: e.target.value }))}
          />

          {isWebchatV2Enabled && (
            <Flex direction={'column'} gap={'1'}>
              <Text size={'2'} color="gray">
                Theme
              </Text>
              <Flex align={'center'} gap={'4'}>
                <Select
                  value={config.theme}
                  onValueChange={(value) => setConfig((prevData) => ({ ...prevData, theme: value as ThemeName }))}
                  items={themes.map((theme) => ({
                    type: 'item',
                    value: theme,
                    content: <Text className="capitalize">{theme}</Text>,
                  }))}
                />

                <Popover
                  trigger={
                    <div className="h-7 w-7 cursor-pointer rounded border" style={{ background: config.themeColor }} />
                  }
                >
                  <TwitterPicker
                    width="325px"
                    color={config.themeColor}
                    colors={pickerColors}
                    onChange={(color) => setConfig((prevData) => ({ ...prevData, themeColor: color.hex }))}
                  />
                </Popover>
              </Flex>
            </Flex>
          )}
        </Flex>
      </ConfigSection>
      <Separator size={'4'} my={'4'} />
      <ConfigSection title="Contact" description="Contact information for your bot.">
        <Flex gap={'4'} direction={'column'}>
          <TextInput
            placeholder="example@example.com"
            value={config.emailAddress}
            onChange={(e) => setConfig((prev) => ({ ...prev, emailAddress: e.target.value }))}
          >
            <TextInputSlot>
              <Icon color="gray" muted icon={HiOutlineMail} />
            </TextInputSlot>
          </TextInput>
          <TextInput
            placeholder="+1 555 555 5555"
            value={config.phoneNumber}
            onChange={(e) => setConfig((prev) => ({ ...prev, phoneNumber: e.target.value }))}
          >
            <TextInputSlot>
              <Icon color="gray" muted icon={HiOutlinePhone} />
            </TextInputSlot>
          </TextInput>
          <TextInput
            placeholder="https://example.com"
            value={config.website}
            onChange={(e) => setConfig((prev) => ({ ...prev, website: e.target.value }))}
          >
            <TextInputSlot>
              <Icon color="gray" muted icon={HiOutlineGlobeAlt} />
            </TextInputSlot>
          </TextInput>
          <TextInput
            label={'Terms of service'}
            placeholder="https://example.com/terms"
            value={config.termsConditions}
            onChange={(e) => setConfig((prev) => ({ ...prev, termsConditions: e.target.value }))}
          />
          <TextInput
            label={'Privacy policy'}
            placeholder="https://example.com/privacy"
            value={config.privacyPolicy}
            onChange={(e) => setConfig((prev) => ({ ...prev, privacyPolicy: e.target.value }))}
          />
          <Button
            loading={isPending}
            onClick={() => {
              updateWebchatIntegration(config)
              void queryClient.invalidateQueries({
                queryKey: getQueryKey('workspaces_/$workspaceId_/bots_/$botId_/webchat', {
                  workspaceId,
                  botId,
                }),
              })
            }}
            className="self-end"
          >
            <Text>Save</Text>
          </Button>
        </Flex>
      </ConfigSection>
    </>
  )
}

function AvatarFormDialog({
  workspaceId,
  botId,
  updateFormData,
  avatarUrl,
}: {
  avatarUrl: string
  botId: string
  workspaceId: string
  updateFormData: React.Dispatch<React.SetStateAction<WebchatIntegrationConfig>>
}) {
  const [newAvatarUrl, setNewAvatarUrl] = useState<string | undefined>(avatarUrl ?? undefined)

  return (
    <Flex className="w-full" direction={'column'}>
      <AvatarForm workspaceId={workspaceId} botId={botId} avatarUrl={newAvatarUrl} setAvatarUrl={setNewAvatarUrl} />
      <DialogFooter
        onConfirm={() => {
          updateFormData((prevData) => ({ ...prevData, avatarUrl: newAvatarUrl }))
        }}
      />
    </Flex>
  )
}

export type AvatarFormProps = {
  botId: string
  workspaceId: string
  avatarUrl?: string
  setAvatarUrl: (url: string | undefined) => void
}

function AvatarForm(props: AvatarFormProps) {
  const {
    mutateAsync: uploadFile,
    error,
    isPending,
  } = useUploadFile({ botId: props.botId, workspaceId: props.workspaceId })

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const mimeType = contentType(acceptedFiles[0]?.type ?? '')
      uploadFile({
        key: `bot-${props.botId}-avatar-${nanoid(6)}`,
        content: acceptedFiles[0],
        accessPolicies: ['public_content'],
        contentType: mimeType ? mimeType : undefined,
      }).then((file) => {
        props.setAvatarUrl(file?.file.url)
      })
    },
  })

  return (
    <div {...getRootProps()} className="w-full">
      <>
        <input {...getInputProps()} />
        <div className="relative flex min-h-[150px] items-center rounded-lg border-2 border-dotted border-gray-4 p-5">
          {isPending ? (
            <Flex justify={'center'} className="w-full">
              <Spinner size="5" />
            </Flex>
          ) : (
            <>
              {isDragActive && (
                <div className="bg-gray-1/80 absolute left-0 top-0 z-[2] flex h-full w-full flex-col items-center justify-center text-gray-11">
                  <ArrowDownOnSquareIcon className="mb-4 h-8 w-8 animate-bounce opacity-50" />
                </div>
              )}
              <>
                <div className="z-1 relative h-[120px] w-[120px]">
                  {props.avatarUrl && (
                    <>
                      {/* Fake mask */}
                      <div className="z-1 absolute left-0 top-0 h-full w-full rounded-sm">
                        <img className="absolute h-full w-full object-cover" src={props.avatarUrl} alt="Bot Avatar" />
                        <div className="absolute h-full w-full bg-black/50" />
                      </div>
                      <img
                        className="relative aspect-square h-full w-full max-w-none rounded-full border border-gray-4 bg-gray-1 object-cover"
                        src={props.avatarUrl}
                        alt="Bot Avatar"
                      />
                    </>
                  )}
                </div>
                <div className="mx-6 h-[96px] w-[1px] bg-gray-4" />
                <div className="flex flex-grow flex-col items-center">
                  <span className="text-sm text-gray-11">Drop image file here</span>
                  <span className="mb-2 mt-1 text-sm text-gray-10">or</span>
                  <Button variant="outline" size={'1'}>
                    Select File
                  </Button>
                </div>
              </>
            </>
          )}
        </div>
        {error && (
          <Callout className="mt-4 p-2" color="red">
            {error.message}
          </Callout>
        )}
      </>
    </div>
  )
}
