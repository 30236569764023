// this file was automatically generated, do not edit
/* eslint-disable */

export interface GetInterfaceRequestHeaders {}

export interface GetInterfaceRequestQuery {}

export interface GetInterfaceRequestParams {
  id: string;
}

export interface GetInterfaceRequestBody {}

export type GetInterfaceInput = GetInterfaceRequestBody & GetInterfaceRequestHeaders & GetInterfaceRequestQuery & GetInterfaceRequestParams

export type GetInterfaceRequest = {
  headers: GetInterfaceRequestHeaders;
  query: GetInterfaceRequestQuery;
  params: GetInterfaceRequestParams;
  body: GetInterfaceRequestBody;
}

export const parseReq = (input: GetInterfaceInput): GetInterfaceRequest & { path: string } => {
  return {
    path: `/v1/admin/interfaces/${encodeURIComponent(input['id'])}`,
    headers: {  },
    query: {  },
    params: { 'id': input['id'] },
    body: {  },
  }
}

export interface GetInterfaceResponse {
  interface: {
    /**
     * ID of the [Interface](#schema_interface)
     */
    id: string;
    /**
     * Creation date of the [Interface](#schema_interface) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the [Interface](#schema_interface) in ISO 8601 format
     */
    updatedAt: string;
    /**
     * Name of the [Interface](#schema_interface)
     */
    name: string;
    /**
     * Version of the [Interface](#schema_interface)
     */
    version: string;
    events: {
      /**
       * Event Definition
       */
      [k: string]: {
        /**
         * Title of the event
         */
        title?: string;
        /**
         * Description of the event
         */
        description?: string;
        schema: {
          [k: string]: any;
        };
      };
    };
    actions: {
      /**
       * Action definition
       */
      [k: string]: {
        /**
         * Title of the action
         */
        title?: string;
        /**
         * Description of the action
         */
        description?: string;
        billable?: boolean;
        cacheable?: boolean;
        input: {
          schema: {
            [k: string]: any;
          };
        };
        output: {
          schema: {
            [k: string]: any;
          };
        };
      };
    };
    entities: {
      /**
       * Entity definition
       */
      [k: string]: {
        /**
         * Title of the entity
         */
        title?: string;
        /**
         * Description of the entity
         */
        description?: string;
        schema: {
          [k: string]: any;
        };
      };
    };
  };
}

