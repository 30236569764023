import { Flex, Separator, Text } from '@radix-ui/themes'
import { useSuspenseInfiniteQuery } from '@tanstack/react-query'
import { Link, useSearch } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { Fragment } from 'react'
import { Avatar, Link as LinkComponent } from '~/elementsv2'
import { StatusBadge } from '~/features/hitl/components/StatusBadge'
import { isConversationStatus } from '~/features/hitl/types'
import { useConversationParticipants } from '~/hooks'
import { ThemeColor } from '~/providers/ThemeProvider'
import { listConversationsInfiniteQuery, listMessagesInfiniteQueryOptions } from '~/queries'
import { useSuspenseQuery } from '~/services'
import { cn } from '~/utils'
import type { Conversation as ConversationObject } from 'hitl-client'

type Props = {
  className?: string
  currentConversationId?: string
  downstreamConversations: ConversationObject[]
} & Parameters<typeof listConversationsInfiniteQuery>[0]

export const ConversationList = ({
  className,
  botId,
  workspaceId,
  currentConversationId,
  downstreamConversations,
  ...props
}: Props) => {
  const searchParams = useSearch({ strict: false })

  return (
    <Flex direction={'column'} gap={'1'} {...props} className={cn(className, 'w-full')}>
      {downstreamConversations.length === 0 ? (
        <Flex direction={'column'} py={'2'} px="1" gap={'1'}>
          <Text size={'2'} weight={'medium'}>
            No conversations found
          </Text>
          {Object.keys(searchParams).length > 0 ? (
            <Text size="2" className="text-gray-11">
              Try changing the filters or{' '}
              <LinkComponent
                preload={false}
                color={ThemeColor}
                to="/workspaces/$workspaceId/bots/$botId/hitl"
                params={{ botId, workspaceId }}
                search={{ ...searchParams, status: undefined, assignee: undefined }}
              >
                clearing them
              </LinkComponent>
            </Text>
          ) : (
            <Text size="2" className="text-gray-11">
              There are no conversations waiting for an agent
            </Text>
          )}
        </Flex>
      ) : (
        downstreamConversations.map((conversation, i) => (
          <ConversationListItem
            key={conversation.id}
            conversation={conversation}
            botId={botId}
            workspaceId={workspaceId}
            index={i}
            selected={currentConversationId === conversation.id}
          />
        ))
      )}
    </Flex>
  )
}

type ConversationProps = {
  className?: string
  botId: string
  workspaceId: string
  conversation: ConversationObject
  index: number
  selected: boolean
}

const ConversationListItem = ({
  selected,
  index,
  className,
  botId,
  workspaceId,
  conversation,
  ...props
}: ConversationProps) => {
  const searchParams = useSearch({ strict: false })

  const participants = useConversationParticipants({
    botId,
    conversationId: conversation.tags['upstream']!,
    workspaceId,
  }).data
  const { data: fetchedMessages } = useSuspenseInfiniteQuery(
    listMessagesInfiniteQueryOptions({ botId, conversationId: conversation.id, workspaceId })
  )
  const workspaceMembers = useSuspenseQuery('workspaces_/$workspaceId_/members', { workspaceId }).data
  const messages = fetchedMessages.pages?.flatMap((page) => page.messages) ?? []
  const lastUserMessage = messages
    .filter((message) => message.direction === 'outgoing' && message.type === 'text')
    .shift()

  // TODO: this is not ideal, this will not work with multiple participants
  const user = participants[0]

  const userName = user?.name ?? 'Anonymous User'
  const hitlStatus = isConversationStatus(conversation.status) ? conversation.status : 'unknown'

  const assigneeId = conversation.assignee?.workspaceMemberId
  const assignee = workspaceMembers?.find((member) => member.id === assigneeId)
  const upstreamConversationId = conversation.tags['upstream']

  const { conversation: upstreamConversation } = useSuspenseQuery(
    'workspaces_/$workspaceId_/bots_/$botId_/$conversationId_',
    {
      botId,
      conversationId: upstreamConversationId ?? '',
      workspaceId,
    }
  ).data

  const integrationName = upstreamConversation ? `${upstreamConversation?.integration} - ` : ''

  return (
    <Fragment key={conversation.id}>
      {index !== 0 && <Separator size={'4'} />}
      <Link
        to="/workspaces/$workspaceId/bots/$botId/hitl"
        className={cn('p-3', selected ? 'bg-gray-3' : 'hover:bg-gray-2')}
        params={{ botId, workspaceId }}
        search={{ ...searchParams, conversationId: conversation.id }}
        preload={false}
      >
        <Flex direction={'column'} gap={'2'} {...props} className={cn(className, 'text-sm')}>
          <Flex align={'center'} gap={'2'}>
            <Avatar variant="soft" size={'2'} pictureUrl={user?.pictureUrl} name={userName + user?.id} />
            <Flex direction={'column'} className="min-w-0 gap-0.5">
              <Flex align={'center'} gap={'2'}>
                <Text className="truncate pr-1">{userName}</Text>
                <StatusBadge status={hitlStatus} />
                {assignee && (
                  <Avatar
                    size={'1'}
                    className="-mt-px h-4 w-4"
                    name={assignee.email}
                    pictureUrl={assignee.profilePicture}
                  />
                )}
              </Flex>
              <Text size={'1'} weight={'light'}>
                {integrationName}
                {DateTime.fromISO(lastUserMessage?.createdAt ?? '').toRelative()}
              </Text>
            </Flex>
          </Flex>
          <Text size={'1'} weight={'medium'}>
            {conversation.title}
          </Text>
          <Text size={'1'} className="line-clamp-3">
            {lastUserMessage?.payload['text']}
          </Text>
        </Flex>
      </Link>
    </Fragment>
  )
}
