import { Badge, Flex, Separator, Text } from '@radix-ui/themes'
import { useNavigate } from '@tanstack/react-router'

import type { Conversation } from 'hitl-client'
import { HiOutlineArrowUturnRight, HiOutlineUser } from 'react-icons/hi2'
import { Identifier, UserBadge } from '~/componentsV2'
import { Button, DataListItem, DataListRoot } from '~/elementsv2'
import {
  useConversationParticipants,
  useCurrentUserWorkspaceMember,
  useSetAssignee,
  useCloseConversation,
} from '~/hooks'
import { ThemeColor } from '~/providers/ThemeProvider'
import { useSuspenseQuery } from '~/services'
import { cn } from '~/utils'

type Props = { className?: string; conversation: Conversation; botId: string; workspaceId: string }

export const ConversationDetails = ({ className, conversation, botId, workspaceId, ...props }: Props) => {
  const navigate = useNavigate()
  const participants = useConversationParticipants({ botId, conversationId: conversation.id, workspaceId }).data

  const workspaceMembers = useSuspenseQuery('workspaces_/$workspaceId_/members', { workspaceId }).data
  const currentWorkspaceMember = useCurrentUserWorkspaceMember({ workspaceId })!

  const assigneeId = conversation.assignee?.workspaceMemberId
  const assignee = workspaceMembers?.find((member) => member.id === assigneeId)

  const shownParticipants = participants?.slice(0, 10) ?? []
  const hiddenParticipants = participants?.slice(10) ?? []

  const isAssignedToMe = assigneeId === currentWorkspaceMember.id

  const { mutate: setAssignee, isPending: isSettingAssignee } = useSetAssignee({ workspaceId, botId })
  const { mutate: closeConversation, isPending: isSolvingTicket } = useCloseConversation({
    workspaceId,
    botId,
    onSuccess: () => void navigate({ to: '/workspaces/$workspaceId/bots/$botId/hitl', params: { botId, workspaceId } }),
  })

  return (
    <Flex direction={'column'} gap={'4'}>
      <DataListRoot orientation={'vertical'} {...props} className={cn(className)}>
        <DataListItem label="Conversation Id">
          <Identifier id={conversation.id} color={ThemeColor} />
        </DataListItem>
        <DataListItem label="Participants">
          <Flex gap={'2'} wrap="wrap">
            {shownParticipants.map((participant) => (
              <UserBadge
                key={participant.id}
                variant="soft"
                avatarName={participant.name ?? `Anonymous User ${participant.id}`}
                name={participant.name ?? 'Anonymous User'}
                pictureUrl={participant.pictureUrl}
              />
            ))}
            {hiddenParticipants.length > 0 && (
              <Badge variant="outline" color="gray">
                <Flex gap={'2'} align={'center'}>
                  <Text>+ {hiddenParticipants.length} more</Text>
                </Flex>
              </Badge>
            )}
          </Flex>
        </DataListItem>
        {assignee && (
          <DataListItem label="Assignee">
            <UserBadge
              variant="soft"
              name={assignee.displayName ?? assignee.email}
              pictureUrl={assignee.profilePicture}
            />
          </DataListItem>
        )}
      </DataListRoot>
      <Separator size={'4'} />

      {conversation.status === 'assigned' && isAssignedToMe && (
        <Button
          trailing={<HiOutlineArrowUturnRight />}
          onClick={() => {
            closeConversation({ conversationId: conversation.id })
          }}
          loading={isSolvingTicket}
        >
          <span>End Conversation</span>
        </Button>
      )}
      {conversation.status === 'pending' && (
        <Button
          disabled={isSettingAssignee}
          variant="soft"
          className="!bg-grass-3 hover:!bg-grass-4"
          color="grass"
          size={'2'}
          leading={<HiOutlineUser />}
          onClick={() => setAssignee({ conversationId: conversation.id, workspaceMemberId: currentWorkspaceMember.id })}
        >
          <span>Assign to me</span>
        </Button>
      )}
    </Flex>
  )
}
