import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { ArrowRightIcon, CheckIcon } from '@radix-ui/react-icons'
import ReactMarkdown from 'react-markdown'
import { markdownComponents } from '../../../components'
import { Button, Spinner } from '../../../elements'
import { useInstallIntegration } from '../../../hooks'
import { useReadFile } from '../../../hooks/useReadFile'
import { showConfirmationDialog, showToast, useQuery } from '../../../services'
import { IntegrationDetails, IntegrationIcon, SuccessCheckmark } from '../../integrations/components'
import { HubInstallPopover } from './HubInstallPopover'
import { PublicIntegration } from '../types'
import { useLocalStore } from '../../../stores'
import { trackEvent } from '../../../providers'
import { InstallPublicIntegrationDisclamer } from './InstallPublicIntegrationDisclamer'
import { openConfigureBotIntegrationStudioTab } from '../services/utils'
import { isBotpressWorkspace } from '~/utils'
import { Badge } from '@radix-ui/themes'
import { HiMiniShieldCheck } from 'react-icons/hi2'
import { Icon } from '~/elementsv2'
import { ComponentProps } from 'react'
import rehypeRaw from 'rehype-raw'

type Props = {
  integration: PublicIntegration
  backLinkElement?: React.ReactNode
  navigateToBotIntegration: (workspaceId: string, botId: string, integrationId: string) => void
  navigateToHome: () => void
  navigateToHub: () => void
  navigateToMaintainerProfile?: (workspace: PublicIntegration['ownerWorkspace']) => void
}

export const HubIntegration = ({ integration, backLinkElement, ...props }: Props) => {
  const { data: readme } = useReadFile({ url: integration?.readmeUrl })
  const { getLastActiveBotId, lastActiveWorkspaceId } = useLocalStore()
  const { data: bots } = useQuery('workspaces_/$workspaceId_/bots_', { workspaceId: lastActiveWorkspaceId ?? '' })
  const lastActiveBot = bots?.find((bot) => bot.id === getLastActiveBotId())
  const { data: activeBot } = useQuery('workspaces_/$workspaceId_/bots_/$botId_', {
    workspaceId: lastActiveWorkspaceId ?? '',
    botId: lastActiveBot?.id ?? '',
  })
  const { installIntegration, isInstallingIntegration } = useInstallIntegration(() => {
    trackEvent({ type: 'integration_installed', integration })
    showToast((toast, toastClient) => (
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center">
          <div className="mr-3 h-8 w-8 self-center">
            <SuccessCheckmark />
          </div>
          Integration installed succesfully
        </div>
        <Button
          className="ml-4"
          onClick={() => {
            toastClient.dismiss(toast.id)
            openConfigureBotIntegrationStudioTab(activeBot?.id ?? '', integration.id)
            props.navigateToHub()
          }}
          trailing={<ArrowRightIcon className="h-4" />}
          variant="primary"
          size="small"
        >
          Configure integration
        </Button>
      </div>
    ))
  })

  if (!integration) {
    return <></>
  }
  const activeBotHasIntegration = Boolean(activeBot?.integrations?.[integration.id])
  const author = integration.ownerWorkspace?.handle ?? ''
  const isVerified = integration.verificationStatus === 'approved' || isBotpressWorkspace(integration.ownerWorkspace.id)

  return (
    <div className="mx-auto w-full max-w-3xl ">
      <div className="mb-10 border-b border-gray-4 pb-6">
        {backLinkElement}
        <div className="flex items-center gap-6">
          <IntegrationIcon className="h-16 rounded-lg border border-gray-4 p-2" iconUrl={integration?.iconUrl} />
          <div>
            <h2 className="mb-1 truncate text-5xl font-bold text-gray-12">{integration?.title}</h2>
            <div className="flex items-center gap-2">
              <p className="text-sm italic text-gray-11">v{integration.version}</p>
              {isVerified && (
                <Badge color="green" className="mr-1" size={'1'}>
                  Verified <Icon size="1" icon={HiMiniShieldCheck} />
                </Badge>
              )}
            </div>
          </div>
          {activeBot ? (
            <>
              <div className="ml-auto mr-2 flex">
                <Button
                  onClick={() => {
                    showConfirmationDialog({
                      confirmLabel: 'Authorize and Install',
                      content: <InstallPublicIntegrationDisclamer integration={integration} bot={activeBot} />,
                      onConfirm: () => {
                        installIntegration(integration.id, lastActiveWorkspaceId!, activeBot.id)
                      },
                    })
                  }}
                  className="whitespace-nowrap rounded-br-none rounded-tr-none border-r border-r-accent-9 px-3 py-0 disabled:border-accent-9 disabled:border-r-accent-9 disabled:bg-accent-7  disabled:text-gray-1 disabled:brightness-90"
                  size="small"
                  variant="primary"
                  disabled={activeBotHasIntegration || isInstallingIntegration}
                >
                  {isInstallingIntegration ? (
                    <Spinner className="mr-3 h-4 w-4 self-center" />
                  ) : (
                    activeBotHasIntegration && <CheckIcon className="mr-3 h-4" />
                  )}
                  {activeBotHasIntegration ? 'Installed' : 'Install'} to {activeBot.name}
                </Button>
                <HubInstallPopover currentIntegration={integration} {...props}>
                  <Button className="rounded-bl-none rounded-tl-none">
                    <ChevronDownIcon className="h-4" />
                  </Button>
                </HubInstallPopover>
              </div>
            </>
          ) : (
            <HubInstallPopover currentIntegration={integration} {...props}>
              <Button
                className="ml-auto mr-2"
                size="small"
                variant="primary"
                trailing={<ChevronDownIcon className="h-4" />}
              >
                Install
              </Button>
            </HubInstallPopover>
          )}
        </div>
        <p className="mt-4 text-gray-11">
          Maintained by&nbsp;
          {props.navigateToMaintainerProfile ? (
            <Button
              onClick={() => props.navigateToMaintainerProfile!(integration.ownerWorkspace)}
              variant="tertiary"
              className="inline-block"
              size="large"
            >
              {author}
            </Button>
          ) : (
            <span className="font-medium">{author}</span>
          )}
        </p>
      </div>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw as any]}
        children={readme}
        components={{
          ...markdownComponents,
          iframe: (iframeProps: ComponentProps<'iframe'>) => {
            const src = iframeProps.src || ''

            const isYouTubeEmbed =
              src.startsWith('https://www.youtube.com/') ||
              src.startsWith('https://youtube.com/') ||
              src.startsWith('https://www.youtube-nocookie.com/')

            // We only allow YouTube embeds to prevent exploits
            if (!isYouTubeEmbed) {
              return <></>
            }

            return (
              <iframe
                src={src}
                className="mb-6 overflow-hidden rounded-lg"
                width="100%"
                height="400"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            )
          },
        }}
      />
      <h1 className="mb-6 mt-24 text-lg text-gray-12">About {integration.title}</h1>
      <IntegrationDetails className="mb-12 " integration={integration} />
    </div>
  )
}
