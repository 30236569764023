import { Box, Tabs, Tooltip } from '@radix-ui/themes'
import { HITLClient, getClient } from '../client'
import { useCurrentUserWorkspaceMember, useWebchatTheme } from '~/hooks'
import { Webchat, WebchatProvider } from '@botpress/webchat'
import { MessageList } from '~/features/conversations/components/MessageList'
import { ListConversationsResponse } from 'hitl-client/src/gen/client/operations/listConversations'
import { useEffect, useState } from 'react'
import { EmptyState } from '~/elementsv2'

type Props = {
  downstreamConversation: ListConversationsResponse['conversations'][0]
  botId: string
  workspaceId: string
}

type Tab = 'history' | 'chat'

const HITLTabs = (props: Props) => {
  const { theme, style } = useWebchatTheme({ themeName: 'eggplant' })
  const currentWorkspaceMember = useCurrentUserWorkspaceMember({ workspaceId: props.workspaceId })
  const [client, setClient] = useState<HITLClient | null>(null)
  const [currentTab, setCurrentTab] = useState<Tab>('history')

  const isAssignedToMe = props.downstreamConversation.assignee?.workspaceMemberId === currentWorkspaceMember!.id
  const livechatDisabled = !isAssignedToMe || props.downstreamConversation.status !== 'assigned'
  const upstreamConversationId = props.downstreamConversation.tags['upstream']

  const initClient = () => getClient({ botId: props.botId, workspaceId: props.workspaceId }).then(setClient)

  useEffect(() => {
    void initClient()
    if (livechatDisabled) {
      setCurrentTab('history')
    }
  }, [props.downstreamConversation.id])

  const handleTabChange = async (tab: Tab) => {
    await initClient()
    setCurrentTab(tab)
  }

  return (
    <Tabs.Root defaultValue={'history'} onValueChange={(t) => void handleTabChange(t as Tab)} value={currentTab}>
      <style>{style}</style>
      <Tabs.List>
        <Tabs.Trigger value="history">Conversation History</Tabs.Trigger>
        {livechatDisabled ? (
          <Tooltip content="You must be assigned to this conversation to chat" side="top">
            <Tabs.Trigger
              value="chat"
              disabled
              className="[&>span]:cursor-disabled [&>span]:bg-transparent [&>span]:text-gray-8"
            >
              Live Chat
            </Tabs.Trigger>
          </Tooltip>
        ) : (
          <Tabs.Trigger value="chat">Live Chat</Tabs.Trigger>
        )}
      </Tabs.List>

      <Box pt="3">
        <Tabs.Content value="history">
          {upstreamConversationId ? (
            <MessageList
              className="max-h-200 min-h-36 @4xl:row-span-2 @4xl:min-h-full @4xl:w-[400px]"
              botId={props.botId}
              conversationId={upstreamConversationId}
              workspaceId={props.workspaceId}
            />
          ) : (
            <EmptyState
              title="Ticket has no corresponding conversation"
              description="You can safely close this ticket"
            />
          )}
        </Tabs.Content>

        <Tabs.Content value="chat">
          {client && (
            <WebchatProvider
              disableRestartConversation={true}
              closeWindow={undefined}
              theme={theme}
              client={client}
              conversationId={props.downstreamConversation.id}
            >
              <Webchat />
            </WebchatProvider>
          )}
        </Tabs.Content>
      </Box>
    </Tabs.Root>
  )
}

export { HITLTabs }
