import { IntegrationFeatureToggle, useAllFeatures, usePublicIntegrations } from '~/hooks'
import { PublicIntegrationSummary } from './types'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { useEffect, useMemo, useState } from 'react'
import Fuse from 'fuse.js'
import { trackEvent } from '~/providers'
import { useDebounce } from 'react-use'

const featureFlaggedIntegrations: Record<string, IntegrationFeatureToggle> = {
  zendesk: 'zendeskIntegration',
  gmail: 'gmailIntegration',
  gsheets: 'gsheetsIntegration',
  mailchimp: 'mailchimpIntegration',
  googlecalendar: 'googleCalendarIntegration',
  dalle: 'dalleIntegration',
  stripe: 'stripeIntegration',
  make: 'makeDotComIntegration',
  chat: 'chat-api',
  discord: 'discordIntegration',
  github: 'githubIntegration',
  openai: 'openaiIntegration',
  groq: 'groqIntegration',
  anthropic: 'anthropicIntegration',
  'fireworks-ai': 'fireworksAiIntegration',
  cerebras: 'cerebrasIntegration',
}

export const useFlaggedIntegrations = (integrations: PublicIntegrationSummary[]) => {
  const allFlags = useAllFeatures()
  return integrations.filter((i) => {
    const flagId = featureFlaggedIntegrations[i.name]
    const deprecated = ['make', 'dalle'].includes(i.name)
    return !deprecated && (!flagId || allFlags.find(({ name }) => name === flagId)?.enabled)
  })
}

const INTEGRATION_CATEGORIES = [
  'Productivity',
  'Channels',
  'Automation',
  'Development',
  'Support',
  'Analytics',
  'Business Operations',
  'New',
  'LLMs / Gen AI',
] as const

export type Category = (typeof INTEGRATION_CATEGORIES)[number]

const integrationCategories: Record<string, Category[]> = {
  'plus/messaging': ['Development'],
  'plus/jira': ['Productivity', 'Development'],
  'simplygreatbots/uuid': ['Development'],
  'simplygreatbots/dalle': ['Development'],
  'plus/segment': ['Analytics'],
  'decay/make': ['Automation'],
  'plus/mixpanel': ['Analytics'],
  'simplygreatbots/jsonwebtoken': ['Development'],
  'simplygreatbots/calendly': ['Automation', 'Productivity'],
  'plus/google-analytics': ['Analytics'],
  'envyro/aws-s3': ['Development'],
  'simplygreatbots/sendgrid': ['Automation'],
  'weavel/weavel': ['Analytics'],
  googlecalendar: ['Productivity'],
  googlesheets: ['Productivity'],
  mailchimp: ['Automation'],
  github: ['Development', 'Productivity'],
  stripe: ['Business Operations'],
  zendesk: ['Channels', 'Support'],
  linear: ['Productivity', 'Development'],
  webhook: ['Automation', 'Development'],
  zapier: ['Automation', 'Development'],
  gmail: ['Channels', 'Productivity'],
  intercom: ['Channels', 'Support'],
  whatsapp: ['Channels'],
  viber: ['Channels'],
  line: ['Channels'],
  instagram: ['Channels'],
  vonage: ['Channels'],
  twilio: ['Channels'],
  teams: ['Channels', 'Productivity'],
  sunco: ['Channels'],
  messenger: ['Channels'],
  slack: ['Channels', 'Productivity'],
  telegram: ['Channels'],
  webchat: ['Channels'],
  asana: ['Productivity'],
  trello: ['Productivity'],
  notion: ['Productivity'],
  openai: ['LLMs / Gen AI'],
  groq: ['LLMs / Gen AI'],
  anthropic: ['LLMs / Gen AI'],
  'fireworks-ai': ['LLMs / Gen AI'],
  cerebras: ['LLMs / Gen AI'],
}

export const useIntegrationCategoriesFilter = (): [
  Category[],
  (selectedCategories: Category[], integrations: PublicIntegrationSummary[]) => PublicIntegrationSummary[],
] => {
  const applyCategoryFilter = (selectedCategories: Category[], integrations: PublicIntegrationSummary[]) => {
    if (!selectedCategories.length) {
      return integrations
    }

    // remove 'New' category from selected categories as it's calculated based on current date
    const categoryFilters = selectedCategories.filter((c) => c !== 'New')
    const shouldFilterRecent = selectedCategories.length !== categoryFilters.length

    const filtered = integrations.filter((i) => {
      const isNew = shouldFilterRecent && DateTime.fromISO(i.createdAt).diffNow('week').weeks * -1 < 2
      const hasCategory =
        categoryFilters.length > 0 && _.intersection(categoryFilters, integrationCategories[i.name] ?? []).length > 0

      return isNew || hasCategory
    })

    return filtered
  }

  return [[...INTEGRATION_CATEGORIES], applyCategoryFilter]
}

export const useSearchIntegrations = (query: string) => {
  const { data } = usePublicIntegrations()
  const integrations = data ?? []
  const [searchResults, setSearchResults] = useState(integrations)
  useEffect(() => {
    setSearchResults(integrations)
  }, [integrations])

  const fuseIndex = useMemo(() => {
    return new Fuse(integrations, {
      shouldSort: true,
      threshold: 0.4,
      keys: ['name', 'description'],
    })
  }, [integrations])

  const searchItem = () => {
    if (!query) {
      return setSearchResults(integrations)
    }

    const res = fuseIndex.search(query).map(({ item }) => item)

    setSearchResults(res)
    trackEvent({
      type: 'hub_search',
      query,
      nResults: res.length,
    })
  }

  useDebounce(searchItem, 300, [query])

  return searchResults
}
