// this file was automatically generated, do not edit
/* eslint-disable */

export interface GetBotRequestHeaders {}

export interface GetBotRequestQuery {}

export interface GetBotRequestParams {
  id: string;
}

export interface GetBotRequestBody {}

export type GetBotInput = GetBotRequestBody & GetBotRequestHeaders & GetBotRequestQuery & GetBotRequestParams

export type GetBotRequest = {
  headers: GetBotRequestHeaders;
  query: GetBotRequestQuery;
  params: GetBotRequestParams;
  body: GetBotRequestBody;
}

export const parseReq = (input: GetBotInput): GetBotRequest & { path: string } => {
  return {
    path: `/v1/admin/bots/${encodeURIComponent(input['id'])}`,
    headers: {  },
    query: {  },
    params: { 'id': input['id'] },
    body: {  },
  }
}

export interface GetBotResponse {
  bot: {
    /**
     * Id of the [Bot](#schema_bot)
     */
    id: string;
    /**
     * Creation date of the [Bot](#schema_bot) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the [Bot](#schema_bot) in ISO 8601 format
     */
    updatedAt: string;
    /**
     * Signing secret of the [Bot](#schema_bot)
     */
    signingSecret: string;
    /**
     * A mapping of integrations to their configuration
     */
    integrations: {
      [k: string]: {
        enabled: boolean;
        /**
         * Name of the [Integration](#schema_integration)
         */
        name: string;
        /**
         * Version of the [Integration](#schema_integration)
         */
        version: string;
        webhookUrl: string;
        webhookId: string;
        identifier?: string;
        configurationType: string | null;
        configuration: {
          [k: string]: any;
        };
        status:
          | "registration_pending"
          | "registered"
          | "registration_failed"
          | "unregistration_pending"
          | "unregistered"
          | "unregistration_failed";
        statusReason: string | null;
        /**
         * ID of the [Integration](#schema_integration)
         */
        id: string;
        /**
         * Creation date of the [Integration](#schema_integration) in ISO 8601 format
         */
        createdAt: string;
        /**
         * Updating date of the [Integration](#schema_integration) in ISO 8601 format
         */
        updatedAt: string;
        /**
         * Title of the integration. This is the name that will be displayed in the UI
         */
        title: string;
        /**
         * Description of the integration. This is the description that will be displayed in the UI
         */
        description: string;
        /**
         * URL of the icon of the integration. This is the icon that will be displayed in the UI
         */
        iconUrl: string;
        /**
         * Idicates if the integration is public. Public integrations are available to all and cannot be updated without creating a new version.
         */
        public: boolean;
        /**
         * Status of the integration version verification
         */
        verificationStatus: "unapproved" | "pending" | "approved" | "rejected";
      };
    };
    /**
     * User object configuration
     */
    user: {
      tags: {
        /**
         * Definition of a tag that can be provided on the object
         */
        [k: string]: {
          /**
           * Title of the tag
           */
          title?: string;
          /**
           * Description of the tag
           */
          description?: string;
        };
      };
    };
    /**
     * Conversation object configuration
     */
    conversation: {
      tags: {
        /**
         * Definition of a tag that can be provided on the object
         */
        [k: string]: {
          /**
           * Title of the tag
           */
          title?: string;
          /**
           * Description of the tag
           */
          description?: string;
        };
      };
    };
    /**
     * Message object configuration
     */
    message: {
      tags: {
        /**
         * Definition of a tag that can be provided on the object
         */
        [k: string]: {
          /**
           * Title of the tag
           */
          title?: string;
          /**
           * Description of the tag
           */
          description?: string;
        };
      };
    };
    /**
     * A mapping of states to their definition
     */
    states: {
      [k: string]: {
        /**
         * Type of the [State](#schema_state) (`conversation`, `user`, `bot` or `task`)
         */
        type: "conversation" | "user" | "bot" | "task";
        /**
         * Schema of the [State](#schema_state) in the `JSON schema` format. This `JSON schema` is going to be used for validating the state data.
         */
        schema: {
          [k: string]: any;
        };
        /**
         * Expiry of the [State](#schema_state) in milliseconds. The state will expire if it is idle for the configured value. By default, a state doesn't expire.
         */
        expiry?: number;
      };
    };
    /**
     * Configuration of the bot
     */
    configuration: {
      /**
       * Configuration data
       */
      data: {
        [k: string]: any;
      };
      /**
       * Schema of the configuration in the `JSON schema` format. The configuration data is validated against this `JSON schema`.
       */
      schema: {
        [k: string]: any;
      };
    };
    /**
     * Events definition
     */
    events: {
      /**
       * Event Definition
       */
      [k: string]: {
        /**
         * Title of the event
         */
        title?: string;
        /**
         * Description of the event
         */
        description?: string;
        schema: {
          [k: string]: any;
        };
      };
    };
    /**
     * Recurring events
     */
    recurringEvents: {
      [k: string]: {
        schedule: {
          cron: string;
        };
        type: string;
        payload: {
          [k: string]: any;
        };
        /**
         * The number of times the recurring event failed to run. This counter resets once the recurring event runs successfully.
         */
        failedAttempts: number;
        /**
         * The reason why the recurring event failed to run in the last attempt.
         */
        lastFailureReason: string | null;
      };
    };
    /**
     * Subscriptions of the bot
     */
    subscriptions: {
      /**
       * Events that the bot is currently subscribed on (ex: "slack:reactionAdded"). If null, the bot is subscribed to all events.
       */
      events: {
        [k: string]: {};
      } | null;
    };
    /**
     * Actions definition
     */
    actions: {
      /**
       * Action definition
       */
      [k: string]: {
        /**
         * Title of the action
         */
        title?: string;
        /**
         * Description of the action
         */
        description?: string;
        billable?: boolean;
        cacheable?: boolean;
        input: {
          schema: {
            [k: string]: any;
          };
        };
        output: {
          schema: {
            [k: string]: any;
          };
        };
      };
    };
    /**
     * Name of the [Bot](#schema_bot)
     */
    name: string;
    /**
     * Last deployment date of the [Bot](#schema_bot) in the ISO 8601 format
     */
    deployedAt?: string;
    /**
     * Indicates if the [Bot](#schema_bot) is a development bot; Development bots run locally and can install dev integrations
     */
    dev: boolean;
    /**
     * Id of the user that created the bot
     */
    createdBy?: string;
    /**
     * Indicates if the [Bot](#schema_bot) should be in always alive mode
     */
    alwaysAlive: boolean;
    /**
     * Status of the bot
     */
    status: "active" | "deploying";
    /**
     * Media files associated with the [Bot](#schema_bot)
     */
    medias: {
      /**
       * URL of the media file
       */
      url: string;
      /**
       * Name of the media file
       */
      name: string;
    }[];
  };
}

