import { useEffect, useState } from 'react'
import Stripe from 'stripe'
import { useCurrentUser } from '../../hooks'
import { useBillingProducts, useBillingSubscription } from '../../hooks/billing'
import { config } from '../../shared/configurations'
import { BillingApiClient } from '../billing/services'
import { usePromoCode } from '~/hooks/billing/usePromoCode'
import { useBillingInfo } from '~/hooks/billing/useBillingInto'

export const COMMUNITY_PLAN_PRODUCT_ID = config.stripeProductIdCommunityPlan
export const TEAM_PLAN_PRODUCT_ID = config.stripeProductIdTeamPlan
export type ProductWithPrice = Stripe.Product & { price: Stripe.Price }
export type ProductQuantityMap = Record<
  string,
  { quantity: number; priceId: string; subscriptionLineItemId?: string; productDetails: ProductWithPrice }
>
//TODO: Refactor this to use suspense once new dashboard is GA
export function useBilling(workspaceId: string) {
  const [productQuantityMap, setProductQuantityMap] = useState<ProductQuantityMap>({})
  const [hasActiveSubscription, setHasActiveSubscription] = useState<boolean>(false)
  const [initialProductQuantityMap, setInitialProductQuantityMap] = useState<ProductQuantityMap>({})
  const [activePlanId, setActivePlanId] = useState<string>(COMMUNITY_PLAN_PRODUCT_ID)
  const [initialActivePlanId, setInitialActivePlanId] = useState<string | undefined>(COMMUNITY_PLAN_PRODUCT_ID)
  const user = useCurrentUser()
  const billingInfo = useBillingInfo(workspaceId)

  function updateProductQuantity(productId: string, quantityDelta: number) {
    const newProductQuantityMap: typeof productQuantityMap = { ...productQuantityMap }
    if (!newProductQuantityMap[productId]) {
      return
    }
    const currentQuantity = newProductQuantityMap[productId]?.quantity ?? 0
    newProductQuantityMap[productId]!.quantity = Math.max(currentQuantity + quantityDelta, 0)
    setProductQuantityMap(newProductQuantityMap)
  }

  function setProductQuantity(productId: string, quantity: number) {
    const newProductQuantityMap: typeof productQuantityMap = { ...productQuantityMap }
    if (!newProductQuantityMap[productId]) {
      return
    }
    newProductQuantityMap[productId]!.quantity = Math.max(quantity, 0)
    setProductQuantityMap(newProductQuantityMap)
  }

  const { products } = useBillingProducts(workspaceId)
  const { subscription, isFetching: isLoadingBillingSubscription } = useBillingSubscription(workspaceId)
  const { promoCode } = usePromoCode(workspaceId)

  useEffect(() => {
    if (products && products.length > 0) {
      const productIdSubscriptionItemMap: Record<string, Stripe.SubscriptionItem> = subscription
        ? subscription.items.data.reduce(
            (acc, item) => {
              acc[item.price.product.toString()] = item
              return acc
            },
            {} as Record<string, Stripe.SubscriptionItem>
          )
        : {}

      const _productQuantityMap: typeof productQuantityMap = {}
      for (const product of products) {
        const productId = product.id.toString()
        const productInSubscription = productIdSubscriptionItemMap[productId]
        if (!productInSubscription) {
          _productQuantityMap[productId] = {
            quantity: 0,
            priceId: product.price.id,
            productDetails: product,
          }
        } else {
          _productQuantityMap[productId] = {
            productDetails: product,
            quantity: productInSubscription.quantity || 0,
            priceId: product.price.id,
            subscriptionLineItemId: productInSubscription?.id,
          }
          if (
            (productInSubscription?.price.product === TEAM_PLAN_PRODUCT_ID ||
              productInSubscription?.price.product === COMMUNITY_PLAN_PRODUCT_ID) &&
            Boolean(productInSubscription?.quantity)
          ) {
            setActivePlanId(productId)
            setInitialActivePlanId(productId)
          }
        }
      }

      // Subscription will always have items (metered usage items) if it exists (even if their quantity is 0)
      if (subscription) {
        setHasActiveSubscription(Boolean(subscription.items.data.length))
      }
      setInitialProductQuantityMap(structuredClone(_productQuantityMap))
      setProductQuantityMap(_productQuantityMap)
    }
  }, [subscription, products])

  async function generateCheckoutSession() {
    return BillingApiClient.generateCheckoutSession(workspaceId, productQuantityMap, user.email)
  }

  return {
    hasActiveSubscription,
    initialProductQuantityMap,
    initialActivePlanId,
    activePlanId,
    subscription,
    productQuantityMap,
    setActivePlanId,
    updateProductQuantity,
    setProductQuantity,
    generateCheckoutSession,
    isLoading: isLoadingBillingSubscription,
    products,
    promoCode,
    billingInfo,
  }
}
