// this file was automatically generated, do not edit
/* eslint-disable */

export interface UpsertFileRequestHeaders {}

export interface UpsertFileRequestQuery {}

export interface UpsertFileRequestParams {}

export interface UpsertFileRequestBody {
  /**
   * Unique key for the file. Must be unique across the bot (and the integration, when applicable).
   */
  key: string;
  /**
   * File tags as an object of key-value pairs. Tag values should be of `string` (text) type.
   */
  tags?: {
    [k: string]: string;
  };
  /**
   * File size in bytes. This will count against your File Storage quota. If the `index` parameter is set to `true`, this will also count against your Vector DB Storage quota.
   */
  size: number;
  /**
   * Set to a value of 'true' to index the file in vector storage. Only certain file formats are currently supported for indexing. Note that if a file is indexed, it will count towards both the Vector DB Storage quota and the File Storage quota of the workspace.
   */
  index?: boolean;
  /**
   * File access policies. Add "public_content" to allow public access to the file content. Add "integrations" to allow read, search and list operations for any integration installed in the bot.
   */
  accessPolicies?: ("public_content" | "integrations")[];
  /**
   * File content type. If omitted, the content type will be inferred from the file extension (if any) specified in `key`. If a content type cannot be inferred, the default is "application/octet-stream".
   */
  contentType?: string;
  /**
   * Expiry timestamp in ISO 8601 format with UTC timezone. After expiry, the File will be deleted. Must be in the future. Cannot be more than 90 days from now. The value up to minutes is considered. Seconds and milliseconds are ignored.
   */
  expiresAt?: string;
  /**
   * Use when your file has "public_content" in its access policy and you need the file\'s content to be immediately accessible through its URL after the file has been uploaded without having to wait for the upload to be processed by our system.
   *
   * If set to `true`, the `x-amz-tagging` HTTP header with a value of `public=true` will need to be sent in the HTTP PUT request to the `uploadUrl` in order for the upload request to work.
   */
  publicContentImmediatelyAccessible?: boolean;
}

export type UpsertFileInput = UpsertFileRequestBody & UpsertFileRequestHeaders & UpsertFileRequestQuery & UpsertFileRequestParams

export type UpsertFileRequest = {
  headers: UpsertFileRequestHeaders;
  query: UpsertFileRequestQuery;
  params: UpsertFileRequestParams;
  body: UpsertFileRequestBody;
}

export const parseReq = (input: UpsertFileInput): UpsertFileRequest & { path: string } => {
  return {
    path: `/v1/files`,
    headers: {  },
    query: {  },
    params: {  },
    body: { 'key': input['key'], 'tags': input['tags'], 'size': input['size'], 'index': input['index'], 'accessPolicies': input['accessPolicies'], 'contentType': input['contentType'], 'expiresAt': input['expiresAt'], 'publicContentImmediatelyAccessible': input['publicContentImmediatelyAccessible'] },
  }
}

export interface UpsertFileResponse {
  file: {
    /**
     * File ID
     */
    id: string;
    /**
     * The ID of the bot the file belongs to
     */
    botId: string;
    /**
     * Unique key for the file. Must be unique across the bot (and the integration, when applicable).
     */
    key: string;
    /**
     * URL to retrieve the file content. This URL will be ready to use once the file is uploaded.
     *
     * If the file has a `public_content` policy, this will contain the permanent public URL to retrieve the file, otherwise this will contain a temporary pre-signed URL to download the file which should be used shortly after retrieving and should not be stored long-term as the URL will expire after a short timeframe.
     */
    url: string;
    /**
     * File size in bytes. Non-null if file upload status is "COMPLETE".
     */
    size: number | null;
    /**
     * MIME type of the file's content
     */
    contentType: string;
    /**
     * The tags of the file as an object of key/value pairs
     */
    tags: {
      [k: string]: string;
    };
    /**
     * File creation timestamp in ISO 8601 format
     */
    createdAt: string;
    /**
     * File last update timestamp in ISO 8601 format
     */
    updatedAt: string;
    /**
     * Access policies configured for the file.
     */
    accessPolicies: ("integrations" | "public_content")[];
    /**
     * Whether the file was requested to be indexed for search or not.
     */
    index: boolean;
    /**
     * Status of the file. If the status is `upload_pending`, the file content has not been uploaded yet. The status will be set to `upload_completed` once the file content has been uploaded successfully.
     *
     * If the upload failed for any reason (e.g. exceeding the storage quota or the maximum file size limit) the status will be set to `upload_failed` and the reason for the failure will be available in the `failedStatusReason` field of the file.
     *
     * However, if the file has been uploaded and the `index` attribute was set to `true` on the file, the status will immediately transition to the `indexing_pending` status (the `upload_completed` status step will be skipped).
     *
     * Once the indexing is completed and the file is ready to be used for searching its status will be set to `indexing_completed`. If the indexing failed the status will be set to `indexing_failed` and the reason for the failure will be available in the `failedStatusReason` field.
     */
    status:
      | "upload_pending"
      | "upload_failed"
      | "upload_completed"
      | "indexing_pending"
      | "indexing_failed"
      | "indexing_completed";
    /**
     * If the file status is `upload_failed` or `indexing_failed` this will contain the reason of the failure.
     */
    failedStatusReason?: string;
    /**
     * File expiry timestamp in ISO 8601 format
     */
    expiresAt?: string;
    /**
     * URL to upload the file content. File content needs to be sent to this URL via a PUT request.
     */
    uploadUrl: string;
  };
}

